import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Container, Grid, Paper } from "@material-ui/core"
import { StaticImage } from "gatsby-plugin-image"

const useStyles = makeStyles((theme) => ({
  root: {
    //padding: theme.spacing(2),
  },
  title: {
    textAlign:'center',
    padding: theme.spacing(3),
  },
  header: {
    textAlign:'center',
  },
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
  },
  sideText: {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems: 'flex-start',
    height:'100%',
  }
}));

function Content() {
  const classes = useStyles();

  return(
    <div className={classes.root}>
      <Container className={classes.title}>
        <Typography variant="h2">Road Trip: British Columbia</Typography>
        <Typography variant="subtitle1">Juni 2021</Typography>
      </Container>

      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.header}>
            <Typography variant="h6">Route</Typography>
          </Grid>
          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/map.png" alt="Karte" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>Vier Tage, drei Nächte. Zweimal durch die Küstenberge (Coast Mountains). 1100 km.</Typography>
            </div>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.header}>
            <Typography variant="h5">Erster Tag</Typography>
            <Typography variant="subtitle1">Vancouver - Penticton</Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>Abfahrt in den Süden. Anstatt auf der Trans-Canada Highway 1 (the Autobahn welche quer durch ganz Canada geht) fuhr ich etwas nördlich, näher an den Bergen entlang, richtung Hope.</Typography>
              <Typography variant="p" paragraph>Der erste Halt hätten eigentlich die Ortello Tunnel sein sollen.
                Wir wollen ja keine Namen nennen, aber irgendjemand hat das GPS falsch eingestellt und irgendwie hab
                ich es geschafft ein paar hundert Meter vom Parkplatz umzudrehen.</Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/hopeslide.jpg" alt="Hope Slide" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>Der <s>zweite</s> erste Halt beim dem grossen Erdrutsch bei Hope (1965). Die ursprüngliche Strasse
                wurde so tief unter Geröll vergraben, dass es einfacher war, eine neue Strasse darüber zu bauen.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/hopesliderest.jpg" alt="Hope Slide" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Der See an der Stelle wurde komplett gefüllt und existiert heute nicht mehr. Das grobe Geröll kam alles vom Berg runter.
              </Typography>
              <Typography variant="p" paragraph>
                Die alte Strasse wäre links im Bild, 55 Meter unter dem Geröll.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/manning.jpg" alt="Manning Regional Park" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Ein Grossteil der Route durch die Berge liegt im Manning Provincial Park. Der Park is riesig und hat mehrere Camping Plätze.
              </Typography>
              <Typography variant="p" paragraph>
                Die einzelnen Plätze für Zelte oder Wohnwagen haben grosszügig Abstand von einander. Alle haben einen Tisch und Feuerstelle.
              </Typography>
              <Typography variant="p" paragraph>
                Idealer Platz für den Mittagssnack.
              </Typography>
              <Typography variant="p" paragraph>
                Die Luft wahr klar mit Duft von Nadelbäumen. Kann nicht warten bis ich mal eine Chance habe mehr Zeit in dem Park zu verbringen. Gibt viele Wanderwege und kleine Seen zu erkunden.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/princeton.jpg" alt="Princeton" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Sobald man aus dem Park und den Bergen raus ist, ändert sich die Landschaft. Hügelig mit viel Landwirtschaft.
              </Typography>
              <Typography variant="p" paragraph>
                Mein nächster Halt war in Princeton. Ein kleines Dorf mit unter 3000 Einwohnern. Nach Vancouver ein
                ziemlicher Kontrast. Es war Sonntag und somit noch ruhiger als normal. Zumindest gemäss den Leuten mit denen ich geredet habe.
              </Typography>
              <Typography variant="p" paragraph>
                Von frühen Siedlern gegründet für den Pelzhandel und Bergbau. Es hat immer noch eine grosse Kupfermiene südlich vom Dorf.
                Vor dem zweiten Weltkrieg gab es noch keine Strasse welche Princeton mit Vancouver verbindet. Pferdekutschen anstatt Autos. Seit die Strasse
                gebaut wurde ist Princeton gewachsen, naja, zumindest ein bisschen.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/princetonmuseum.jpg" alt="Princeton Museum" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                In Princeton hat es ein kleines Museum. Ein paar "old Ladies" betreiben es. Wirklich liebevoll gemacht.
              </Typography>
              <Typography variant="p" paragraph>
                Vor ein paar Jahren haben sie ein Fossil von einer bisher unbekannten Spezies entdeckt (eine Libelle).
              </Typography>
              <Typography variant="p" paragraph>
                Die kleine Holzhütte ist original. Eine Familie mit sieben Kinder hat darin gelebt.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/princetonphone.jpg" alt="Princeton Museum" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Im Museumsgebäude habe ich dann auch Princetons Internet Anschluss gefunden!
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/farmstore.jpg" alt="Farm Store" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Zwischen Princeton und Penticton hat es Hofläden alle paar Kilometer. Erstaunlich gross.
              </Typography>
              <Typography variant="p" paragraph>
                Wenn ich nur so nah am Supermarkt in Vancouver parkieren könnte!
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/penticton.jpg" alt="Penticton" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Penticton liegt zwischen zwei Seen. Mein erstes Hotel liegt nahe am Ufer des oberen Sees.
              </Typography>
              <Typography variant="p" paragraph>
                Mit etwas über 30'000 Einwohner, klar grösser als Princeton.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/rips.jpg" alt="Rips" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Nach ein paar hundert Kilometer, muss die Diet mal hinten anstehen.
              </Typography>
              <Typography variant="p" paragraph>
                Erste Pomme Frites in 12 Monaten.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/sunset1.jpg" alt="Sunset" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Der Sonnenuntergang war fantastisch. Mit dem Hotel gerade am Seeufer, konnte ich schnell zum Auto
                und die Kamera + Stativ holen.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/sunset2.jpg" alt="Sunset" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Haufenweise Kommentare von Leuten welche mit iPhones Bilder machten und sich ärgerten dass sie selbst die Kameras zuhause liessen :D
              </Typography>
            </div>
          </Grid>

        </Grid>
      </Paper>


      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.header}>
            <Typography variant="h5">Zweiter Tag</Typography>
            <Typography variant="subtitle1">Penticton - Kamloops</Typography>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/lakeroad.jpg" alt="Lake road" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Am nächsten Tag ging es nördlich am Okanagan Lake entlang.
              </Typography>
              <Typography variant="p" paragraph>
                Eine der schönsten Strassen die ich jemals gefahren bin. Schlängelt sich dem See entlang,
                praktisch kein Verkehr und keine Geschwindigkeitskontrollen. Mehr Motorräder als Autos.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/lakeroad2.jpg" alt="Lake road" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                60km langer Traum, mit dem See auf der einen Seite und Wald auf der anderen.
              </Typography>
              <Typography variant="p" paragraph>
                Alle paar Kilometer hat es Platz zum parkieren. Auf einem dieser Plätze habe ich hier das Auto abgestellt.
              </Typography>
              <Typography variant="p" paragraph>
                Etwa 5 KM weiter, hatte es eine Gruppe Bergziegen im Schatten von ein paar Bäumen an einer dieser Plätze.
                Leider kein Photo von den schönen Tieren, die Gruppe stand auf der einzigen Parkmöglichkeit.
              </Typography>
              <Typography variant="p" paragraph>
                Das Land war ziemlich trocken. Stellenweise zeigten die Bäume Spuren von früheren Waldbränden.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/bear1.jpg" alt="Bear" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Kurz vor Kamloops hat es ein Wildtierpark. Mit Tieren die lokal gerettet wurden.
              </Typography>
              <Typography variant="p" paragraph>
                Es war super heiss und meine Sonnencreme war nirgends zu finden. Zum Glück hat es eine Apotheke ein paar
                Minuten vom Park weg. Auto in den Ferien dabei zu haben ist schon praktisch.
              </Typography>
              <Typography variant="p" paragraph>
                Die meisten Tiere waren mindestens so schlapp im heissen Wetter wie ich. Nur die Bären waren aktiv und
                spielten im Wasser.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/bear2.jpg" alt="Bear" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                War gar nicht so einfach zu Fotografieren. Viele Pflanzen im Weg und alles durch eine alte Plexiglassscheibe.
              </Typography>
              <Typography variant="p" paragraph>
                Nebst mir, waren noch zwei Familien im Park. Der Park ist gross, aber wir trafen uns bei den Bären. Wir beneideten die Bären für die Abkühlung.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/kamloops.jpg" alt="Kamloops" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Kamloops ist grösser als Princeton, wirkt aber viel verschlafener, trotz 100'000 Einwohnern.
              </Typography>
              <Typography variant="p" paragraph>
                Viel Zeit habe ich nicht in der Stadt verbracht. Ich kam am späten Nachmittag an und fuhr früh am Morgen weiter.
              </Typography>
            </div>
          </Grid>

        </Grid>
      </Paper>

      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.header}>
            <Typography variant="h5">Dritter Tag</Typography>
            <Typography variant="subtitle1">Kamloops - Whistler</Typography>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/sanatorium.jpg" alt="Sanatorium" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Etwas westlich von Kamloops hat es eine verlassene Nervenheilanstalt. Da sollen heute noch Geister leben.
              </Typography>
              <Typography variant="p" paragraph>
                Es ist wie ein kleines Dorf, mit mehreren Gebäuden. Leider alles abgesperrt. Jemand hat das alles aufgekauft und bietet jetzt Touren an.
                Natürlich war alles geschlossen dank COVID.
              </Typography>
              <Typography variant="p" paragraph>
                Ein paar Filme wurden hier auch gedreht. "Das A-Team" und "Firewall".
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/pastkamloops.jpg" alt="Towards Lilooet" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Nach dem ich keine Geister gefunden habe, ging es wieder richtig Bergkette.
              </Typography>
              <Typography variant="p" paragraph>
                Zwischen Kamloops und Lillooet war die Strasse komplett leer. Für ca. 100 KM hatte ich weder ein Auto
                vor mir noch hinter mir, und 3-4 Autos die mir entgegen kamen.
              </Typography>
              <Typography variant="p" paragraph>
                Im Grass hat es kleine Kakteen mit Stacheln die so scharf sind, sie gehen locker 5+ Millimeter in the Sohle von Schuhen rein.
                Sie sind gut im Grass versteckt, aber ich hab sie trotzdem gefunden... mehrmals... und dann wieder auf dem Weg zurück zum Auto...
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/beforelilooet.jpg" alt="Lillooet" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Auf dem Bild versteckt ist Lillooet. Das "grosse Zentrum" vor die Berge richtig beginnen.
              </Typography>
              <Typography variant="p" paragraph>
                In Vordergrund ist die alte Brücke. Leider nicht offen für Autos. Auf der Strasse runter zur
                Brücke war ein Reh.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/reh.jpg" alt="Reh" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Das Reh war auf der Strasse und lief den Hang ab. Konnte gerade noch ein Bild machen. Wenn ich mich nicht irre, ist das ein Mule-deer.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/sluice.jpg" alt="Sluice Box" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Als ich das Reh suchte, sah ich diese Installation. Ziemlich sicher das ist eine Sluice-Box. Wird verwendet um Gold zu waschen.
              </Typography>
              <Typography variant="p" paragraph>
                Das ganze Gebiet hat Gold. Es gab hier einen Goldrausch vor langer Zeit und es hat immer noch viele verlassene und aktive Goldminen.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/seton.jpg" alt="Seton Lake" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Etwas westlich von Lillooet ist Seton Lake. Perfekter Platz für mein Mittagessen das ich in Lillooet gekauft hatte.
              </Typography>
              <Typography variant="p" paragraph>
                Es war sehr warm, aber dank dem See hatte es eine nette Brise. Ich konnte mich kaum aufreisen um weiterzufahren. Das stellte sich als Problem heraus...
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/eyestop.jpg" alt="Seton Lake" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Als ich weiterfuhr, begannen meine Augen zu tränen. Ich hatte wohl zu viel Wind abgekommen. Schöne Strasse, aber wenn die Augen schmerzen ist es nicht mehr so toll.
              </Typography>
              <Typography variant="p" paragraph>
                Nach ein paar Minuten gab ich auf und machte eine Pause am Fluss. Das half, aber so wirklich Spass machte die Rest der Fahrt an dem Tag nicht mehr.
              </Typography>
            </div>
          </Grid>

        </Grid>
      </Paper>


      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.header}>
            <Typography variant="h5">Vierter Tag</Typography>
            <Typography variant="subtitle1">Whistler - Vancouver</Typography>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/whistler.jpg" alt="Whistler" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Nach all den schönen kleinen Städtchen, war Whistler doch etwas zu touristisch.
                Aber ich wollte nicht direkt nach Vancouver durchfahren.
                Nach 5-6 Stunden fahrt, und mit entzünden  Augen, hatte ich keine Lust mir den Vancouver Innenstadtverkehr anzutun.
              </Typography>
              <Typography variant="p" paragraph>
                Ich nahm die Gondel auf den Berg. Ich wahr noch nie da oben, und in Whistler selbst gibt es nicht viel anzusehen.
              </Typography>
              <Typography variant="p" paragraph>
                Whistler hat eine Gipfel-zu-Gipfel Seilbahn welche die beiden Skigebiete von Whistler verbindet.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} xl={6}>
            <StaticImage src="../../data/blog/202106roadtrip/whistler2.jpg" alt="Whistler" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.sideText}>
              <Typography variant="p" paragraph>
                Das Wetter began zu drehen. Ich hatte wirklich Wetterglück auf dem Trip. Immer schön Wetter aber noch
                keine Waldbrände.
              </Typography>
              <Typography variant="p" paragraph>
                Es hat viele Wanderwege auf den zwei Bergen. Vielleicht mal ein Besuch wert. Muss aber selbst in Juni noch mit Schneefelder rechnen.
              </Typography>
            </div>
          </Grid>

        </Grid>
      </Paper>
    </div>
  );
}

export default function Home() {
  return <div>
    <Content />
  </div>
}
